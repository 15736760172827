@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-20px);
		-ms-transform:     translateY(-20px);
		transform:         translateY(-20px)
	}
	60% {
		-webkit-transform: translateY(-10px);
		-ms-transform:     translateY(-10px);
		transform:         translateY(-10px)
	}
}

.bounce-effect {
    animation: bounce 0.5s;
  }