.react-select__control {
  height: auto !important;
  min-height: 33px !important;
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.react-select__single-value {
  color: $input-color !important;
}

.react-select__input {
  color: $input-color !important;
}

.react-select__indicator {
  padding: 3px !important;
}

.react-select__control--is-focused {
  border-color: $primary !important;
  box-shadow: 0 0 0 1px $primary !important;
}


.react-select__menu {
    background-color: $input-bg !important;
}

.react-select__menu-list {
    background-color: $input-bg !important;



}
.react-select__option {

    &:hover{
        background-color: lighten($primary,10%) !important;
        color: #fff!important;
    }


}
.react-select__option--is-focused {

    background-color: lighten($primary,10%) !important;
    color: #fff!important;

}
.react-select__option--is-selected {

    background-color: $primary !important;
    color: #fff!important;

}

